$primary-color: #dc3545;
$primary-text-color : #8b054d;
$secondary-color : #17a2b8;
$tertiary-white : #fff;
$tertiary-black : #000;
$light-black : #1c1d1f;
$yellow : #fba100;
$bg-color : #f5f5f5;
$grey: grey;


// new colors
$independence: rgba(84, 94, 117, 1);
$blue-jeans: rgba(99, 173, 242, 1);
$baby-blue-eyes: rgba(167, 204, 237, 1);
$yale-blue: rgba(48, 77, 109, 1);
$air-superiority-blue: rgba(130, 160, 188, 1);