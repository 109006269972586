* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html, body {
  margin: 0px;
  padding: 0px;
}

body a {
  padding: 0px;
  text-decoration: none;
}

body a:hover {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
}

p {
  padding: 0px;
}

ul {
  padding: 0;
  margin: 0;
}

/** reset code **/
.custom-card-1 {
  background: #fff;
  border-radius: 2rem;
  -webkit-box-shadow: 0 0 3.4rem 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 3.4rem 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto 10rem;
  padding: 3rem 4rem;
}

.nav-link {
  color: #304d6d;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: inherit;
  color: #17a2b8;
  border: 1px solid #17a2b8;
  border-left: none;
  border-right: none;
}

.btn2 {
  padding: 5px 20px;
  background-color: #17a2b8;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
