* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html, body {
  margin: 0px;
  padding: 0px;
}

body a {
  padding: 0px;
  text-decoration: none;
}

body a:hover {
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
}

p {
  padding: 0px;
}

ul {
  padding: 0;
  margin: 0;
}

/** reset code **/
/** Login **/
.wrapper-2 {
  background: url("../../imgs/arch.jpeg") center center;
  background-size: cover;
  height: 100vh;
}

.wrapper-3 {
  background: url("../../imgs/bg3.jpeg") center center;
  background-size: cover;
  font-weight: bolder;
  /* height: 100vh;  */
}

.wrapper-2-gradient {
  font-weight: bold;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.45)), to(rgba(0, 0, 0, 0.45)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45));
  height: 100%;
}

.wrapper-3-gradient {
  font-weight: bold;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 243, 255, 0.45)), to(rgba(0, 0, 0, 0.45)));
  background-image: linear-gradient(rgba(0, 243, 255, 0.45), rgba(0, 0, 0, 0.45));
  height: 100%;
}

.login__container {
  width: 100%;
  max-height: 100vh;
}

.login__container .login {
  position: absolute;
  min-width: 35%;
  height: auto;
  background-color: #f5f5f5b6;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 5px;
}

.login__container .login .title__container {
  width: 100%;
  height: 150px;
  text-align: center;
}

.login__container .login .title__container h5 {
  padding-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: lighter;
  font-size: 18px;
}

.login__container .login .form__container {
  width: 100%;
}

.login__container .login .form__container form label {
  width: 100%;
  display: inline-block;
  padding: 8px 0px;
  font-family: sans-serif;
}

.login__container .login .form__container form input[type=text], .login__container .login .form__container form input[type=password] {
  width: 100%;
  height: 40px;
  border: 1px solid #D8D8D8;
  padding: 0px 8px;
  font-family: sans-serif;
}

.login__container .login .form__container form button[type=submit] {
  border: none;
  background-color: #0080A3;
  width: 100px;
  height: 40px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 17px;
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.login__container .login .create-user {
  padding: 10px 10px 5px 0px;
  border-bottom: 1px solid black;
  margin-right: 15px;
}

.login__container .login .create-user .link {
  color: #000;
}

/** Register User */
.register__user {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding: 20px 0px;
}

.register__user .logo__container {
  width: 100%;
  height: 200px;
  display: block;
  text-align: center;
  padding: 10px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.register__user .logo__container h1 {
  color: #003997;
  font-family: sans-serif;
}

.register__user .registration__form {
  width: 80%;
  margin: 40px auto;
  background-color: #fff;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  height: 100%;
  padding: 20px;
}

.register__user .registration__form form h3 {
  padding: 10px 0px 4px 0px;
  font-family: sans-serif;
  font-size: 25px;
  text-decoration: underline;
}

.register__user .registration__form form .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.register__user .registration__form form .column input[type=text].outer, .register__user .registration__form form .column input[type=password].outer {
  width: 100%;
  margin: 5px;
  height: 50px;
  border: 2px solid #000;
  padding: 0px 8px;
  font-family: sans-serif;
  border-radius: 5px;
}

.register__user .registration__form form .column .single__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin: 5px;
}

.register__user .registration__form form .column .single__column select {
  height: 50px;
  border-radius: 5px;
  border: 2px solid #000;
  margin-bottom: 10px;
  -moz-appearance: none !important;
  background: transparent url("data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==") right center no-repeat !important;
  background-position: calc(100% - 5px) center !important;
}

.register__user .registration__form form .column .single__column input[type=text].inner, .register__user .registration__form form .column .single__column input[type=password].outer {
  width: 100%;
  height: 50px;
  border: 2px solid #000;
  padding: 0px 8px;
  font-family: sans-serif;
  border-radius: 5px;
}

.register__user .registration__form form .plan__list {
  text-align: center;
  /* The container */
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}

.register__user .registration__form form .plan__list h2 {
  font-family: sans-serif;
  letter-spacing: 1px;
  font-weight: lighter;
}

.register__user .registration__form form .plan__list .container {
  display: inline-block;
  position: relative;
  padding-right: 55px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.register__user .registration__form form .plan__list .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.register__user .registration__form form .plan__list .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.register__user .registration__form form .plan__list .container:hover input ~ .checkmark {
  background-color: #ccc;
}

.register__user .registration__form form .plan__list .container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.register__user .registration__form form .plan__list .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.register__user .registration__form form .plan__list .container input:checked ~ .checkmark:after {
  display: block;
}

.register__user .registration__form form .plan__list .container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.register__user .registration__form form .plan__container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.register__user .registration__form form .plan__container .plan {
  width: 100%;
  background-color: #fff;
  height: 250px;
  margin: 5px;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  text-align: center;
  border-radius: 5px;
  position: relative;
}

.register__user .registration__form form .plan__container .plan span {
  font-family: sans-serif;
  font-weight: lighter;
}

.register__user .registration__form form .plan__container .plan h5 {
  font-family: sans-serif;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.register__user .registration__form form .plan__container .plan h6 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.register__user .registration__form form .plan__container .plan button {
  position: absolute;
  width: 100%;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #17a2b8;
  left: 0;
  right: 0;
  bottom: 0px;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
}

.register__user .submit__btn {
  text-align: center;
}

.register__user .submit__btn input[type=submit] {
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #17a2b8;
  color: #000;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  margin: 20px;
  cursor: pointer;
  font-weight: lighter;
}

/** Dashboard */
.dashboard__container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  min-height: 100vh;
  /** Sidebar */
}

.dashboard__container .swal-height {
  height: 100px !important;
}

.dashboard__container .sidebar {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 20%;
}

.dashboard__container .sidebar .sidebar__container {
  border-right: 1px solid #f8f8f8;
  height: 100%;
  background-color: #ffffff;
}

.dashboard__container .sidebar .sidebar__container .sidebar__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 70px;
  height: 70px;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.dashboard__container .sidebar .sidebar__container .sidebar__title img {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: absolute;
  max-width: 60%;
  max-height: 60%;
  left: 10px;
  cursor: pointer;
}

.dashboard__container .sidebar .sidebar__container .sidebar__title .hamburger__menu {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.dashboard__container .sidebar .sidebar__container .sidebar__title .hamburger__menu span:first-child {
  width: 20px !important;
}

.dashboard__container .sidebar .sidebar__container .sidebar__title .hamburger__menu span:last-child {
  width: 18px !important;
}

.dashboard__container .sidebar .sidebar__container .sidebar__title .hamburger__menu span {
  width: 30px;
  display: block;
  background-color: #000;
  margin-bottom: 4px;
  height: 2px;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav {
  padding: 10px;
  list-style: none;
  font-family: sans-serif;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li {
  display: block;
  font-size: 18px;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu {
  list-style: none;
  padding: 10px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  margin-bottom: 15px;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu li {
  display: inline-block;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu li .main__link {
  color: #777;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu li i {
  color: #777;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu li:first-child {
  width: 90%;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu li:last-child {
  width: 10%;
  text-align: right;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu:hover {
  background-color: #EFF7FE;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu:hover li .main__link {
  color: #304d6d;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu:hover li i {
  color: #304d6d !important;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu.active {
  background-color: #EFF7FE;
  color: white !important;
  border-radius: 5px;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu.active li .main__link {
  color: #304d6d;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.main_menu.active li i {
  color: #304d6d !important;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu {
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  padding: 0px 60px;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu li {
  padding: 5px 0px;
  text-align: left;
  font-size: 13px;
  white-space: nowrap;
  font-family: sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu li .link {
  color: #000;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu ul li {
  padding-left: 10px;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu ul li .link {
  color: #003380;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu li:hover {
  text-decoration: underline;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu.close {
  display: none;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 0;
}

.dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu.open {
  display: block;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.dashboard__container .dashboard__content {
  -webkit-box-flex: 4;
      -ms-flex: 4;
          flex: 4;
  width: 80%;
  position: relative;
  background-color: #f5f5f5;
  border-left: 1px solid #ccc;
}

.dashboard__container .dashboard__content .subscription__container {
  width: 90%;
  margin: 40px auto;
}

.dashboard__container .dashboard__content .subscription__container h2 {
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.dashboard__container .dashboard__content .subscription__container .plan__list {
  text-align: center;
  /* The container */
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}

.dashboard__container .dashboard__content .subscription__container .plan__list h2 {
  font-family: sans-serif;
  letter-spacing: 1px;
  font-weight: lighter;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  padding-right: 55px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .container:hover input ~ .checkmark {
  background-color: #ccc;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .container input:checked ~ .checkmark:after {
  display: block;
}

.dashboard__container .dashboard__content .subscription__container .plan__list .container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.dashboard__container .dashboard__content .subscription__container .plan__container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .subscription__container .plan__container .plan {
  width: 100%;
  background-color: #fff;
  height: 250px;
  margin: 5px;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  text-align: center;
  border-radius: 5px;
  position: relative;
}

.dashboard__container .dashboard__content .subscription__container .plan__container .plan span {
  font-family: sans-serif;
  font-weight: lighter;
}

.dashboard__container .dashboard__content .subscription__container .plan__container .plan h5 {
  font-family: sans-serif;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.dashboard__container .dashboard__content .subscription__container .plan__container .plan h6 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.dashboard__container .dashboard__content .subscription__container .plan__container .plan button {
  position: absolute;
  width: 100%;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #17a2b8;
  left: 0;
  right: 0;
  bottom: 0px;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
}

.dashboard__container .dashboard__content .subscription__container .subscription__btn {
  padding: 15px 50px;
  border: none;
  margin-top: 20px;
  text-align: center;
  background-color: #17a2b8;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.dashboard__container .dashboard__content .dashboard__title {
  padding: 20px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e8e8e8;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#e8e8e8), to(#ffffff));
  background-image: linear-gradient(0deg, #e8e8e8 0%, #ffffff 100%);
}

.dashboard__container .dashboard__content .dashboard__title .dash__left {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.dashboard__container .dashboard__content .dashboard__title .dash__right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
}

.dashboard__container .dashboard__content .dashboard__title .dash__right ul {
  list-style: none;
}

.dashboard__container .dashboard__content .dashboard__title .dash__right ul li {
  display: inline-block;
}

.dashboard__container .dashboard__content .dashboard__title .dash__right ul li i {
  font-size: 18px;
}

.dashboard__container .dashboard__content .dashboard__title .dash__right ul li:first-child {
  padding-right: 25px;
}

.dashboard__container .dashboard__content .reporting {
  width: 60%;
  margin: 40px auto;
}

.dashboard__container .dashboard__content .reporting h2 {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 20px;
  text-align: center;
}

.dashboard__container .dashboard__content .reporting form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .reporting form input[type=text] {
  height: 50px;
  padding: 0px 8px;
  font-family: sans-serif;
  width: 100%;
  border-radius: 5px 0px 0px 5px;
  border: none;
}

.dashboard__container .dashboard__content .reporting form button[type=submit] {
  width: 75px;
  height: 50px;
  background-color: #17a2b8;
  color: #000;
  border: none;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.dashboard__container .dashboard__content .reporting form button[type=submit] i.fas.fa-search, .dashboard__container .dashboard__content .reporting form button[type=submit] i.fas.fa-spinner.fa-spin {
  font-size: 20px;
}

.dashboard__container .dashboard__content .dashboard__analytics {
  min-height: 210px;
  background-color: #a7cced;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards {
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box {
  width: 200px;
  height: 120px;
  background-color: #f8f9fe;
  border-radius: 5px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5px;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div h5 {
  text-transform: uppercase;
  color: #8898aa !important;
  font-size: 14px;
  padding-bottom: 6px;
  font-weight: 500;
  font-family: Open Sans,sans-serif;
  letter-spacing: 1px;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div h6 {
  font-size: 20px;
  font-weight: 700;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div i.fas.fa-user {
  padding: 10px;
  background-color: rosybrown;
  border-radius: 25px;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div i.fas.fa-search, .dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div i.fas.fa-file {
  padding: 10px;
  background-color: salmon;
  border-radius: 25px;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div i.fas.fa-user-shield {
  padding: 10px;
  background-color: sienna;
  border-radius: 25px;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div i.fas.fa-edit {
  padding: 10px;
  background-color: teal;
  border-radius: 25px;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div.card__icon {
  text-align: right;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__analytics {
  margin-top: 20px;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__analytics h5 {
  color: #2dce89 !important;
}

.dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__analytics h5 span {
  font-weight: lighter;
  letter-spacing: 1px;
  color: #000 !important;
}

.dashboard__container .dashboard__content .breadcrumb__title {
  width: 95%;
  margin: 10px auto;
  padding: 20px;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 3px solid #dddddd;
}

.dashboard__container .dashboard__content .add-search {
  width: 95%;
  margin: 10px auto;
  padding: 10px 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .add-search .add {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: left;
}

.dashboard__container .dashboard__content .add-search .add a {
  padding: 10px 20px;
  background-color: #17a2b8;
  color: #fff;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}

.dashboard__container .dashboard__content .add-search #global {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .add-search #global .global__search {
  width: 30%;
}

.dashboard__container .dashboard__content .add-search .search {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
}

.dashboard__container .dashboard__content .add-search .search form input[type=text], .dashboard__container .dashboard__content .add-search .search form .button {
  height: 40px;
}

.dashboard__container .dashboard__content .add-search .search form .button {
  width: 50px;
  border: none;
  background-color: #17a2b8;
  border: 1px solid #dddddd;
}

.dashboard__container .dashboard__content .add-search .search form input[type=text] {
  padding: 8px;
  font-family: sans-serif;
  width: 70%;
  border: 1px solid #dddddd;
}

.dashboard__container .dashboard__content .table__container {
  max-width: 95%;
  margin: 20px auto;
  border-radius: 5px;
  height: auto;
  overflow-x: scroll;
  padding: 20px 0px;
}

.dashboard__container .dashboard__content .table__container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  overflow-x: scroll;
  border: none;
  width: 100%;
}

.dashboard__container .dashboard__content .table__container td, .dashboard__container .dashboard__content .table__container th {
  border: 1px solid rgba(0, 0, 0, 0.13);
  text-align: left;
  padding: 8px;
}

.dashboard__container .dashboard__content .table__container th {
  background-color: #304d6d;
  color: white;
}

.dashboard__container .dashboard__content .table__container td ul {
  list-style: none;
  text-align: center;
}

.dashboard__container .dashboard__content .table__container td ul li {
  display: inline-block;
  padding: 10px 20px;
}

.dashboard__container .dashboard__content .table__container td ul li a {
  padding: 5px 20px;
  background-color: #17a2b8;
  color: #fff;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .table__container td .approved__btn {
  padding: 10px;
  display: block;
  width: 100%;
  background-color: #66cd9a;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

.dashboard__container .dashboard__content .table__container td .approved__btn_RA, .dashboard__container .dashboard__content .table__container td .approved__btn_user, .dashboard__container .dashboard__content .table__container td .rejected__btn {
  padding: 10px;
  display: block;
  width: 100%;
  color: #fff;
  border: none;
  font-weight: bold;
}

.dashboard__container .dashboard__content .table__container td .approved__btn_RA {
  background-color: #5e72e4;
}

.dashboard__container .dashboard__content .table__container td .approved__btn_user {
  background-color: #0080A3;
}

.dashboard__container .dashboard__content .table__container td .rejected__btn {
  background-color: #ec4141;
}

.dashboard__container .dashboard__content .table__container td .info__btn, .dashboard__container .dashboard__content .table__container td .delete__btn {
  display: block;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard__container .dashboard__content .table__container td .info__btn {
  color: #26363b;
  border: 2px solid #26363baf;
  padding: 5px;
  margin-top: 5px;
}

.dashboard__container .dashboard__content .table__container td .info__btn:hover {
  background-color: white;
}

.dashboard__container .dashboard__content .table__container td .delete__btn {
  padding: 10px;
  background-color: #e83663;
  color: white;
  border: none;
}

.dashboard__container .dashboard__content .table__container td .delete__btn:hover {
  background-color: #db2121;
}

.dashboard__container .dashboard__content .table__container td a.view {
  padding: 5px 20px;
  background-color: #17a2b8;
  color: #fff;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .table__container td a.delete {
  padding: 5px 20px;
  background-color: #f5365c;
  color: #fff;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .table__container td .btn__approve {
  background-color: #66cd9a;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  color: #fff;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
}

.dashboard__container .dashboard__content .table__container td .btn__approve:hover {
  background-color: #12a766;
}

.dashboard__container .dashboard__content .table__container td .btn__reject {
  background-color: #e83663;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
}

.dashboard__container .dashboard__content .table__container td .btn__reject:hover {
  background-color: #db2121;
}

.dashboard__container .dashboard__content .table__container tr:nth-child(even) {
  background-color: #dddddd;
}

.dashboard__container .dashboard__content .pagination {
  width: 95%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.dashboard__container .dashboard__content .pagination .pagination__btn {
  border: none;
  padding: 10px 20px;
  background-color: #66cd9a;
  font-weight: bold;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #000;
  cursor: pointer;
  margin: 5px;
}

.dashboard__container .dashboard__content .upload__container {
  margin: 40px;
}

.dashboard__container .dashboard__content .upload__container h2 {
  font-family: sans-serif;
  border-bottom: 2px solid black;
  padding: 5px 0px;
}

.dashboard__container .dashboard__content .upload__container form {
  margin-top: 100px;
}

.dashboard__container .dashboard__content .upload__container form label {
  padding-right: 200px;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: bold;
}

.dashboard__container .dashboard__content .upload__container form input[type=file] {
  border: 1px solid black;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .upload__container form input[type=submit] {
  padding: 13px 20px;
  border: none;
  background-color: #17a2b8;
  font-family: sans-serif;
  letter-spacing: 1px;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .document__container {
  margin: 40px;
  height: auto;
  background-color: #fff;
  padding: 20px;
}

.dashboard__container .dashboard__content .document__container h2 {
  padding-bottom: 10px;
  font-family: sans-serif;
}

.dashboard__container .dashboard__content .document__container .document {
  width: 180px;
  background-color: #f5f5f5;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin: 5px;
}

.dashboard__container .dashboard__content .document__container .document .view__download ul {
  list-style: none;
}

.dashboard__container .dashboard__content .document__container .document .view__download ul li {
  display: inline-block;
  padding-right: 5px;
  cursor: pointer;
}

.dashboard__container .dashboard__content .registration__page {
  position: relative;
}

.dashboard__container .dashboard__content .registration__page h3 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 20px 0px 0px 20px;
  font-size: 30px;
  z-index: 9999;
}

.dashboard__container .dashboard__content .registration__page .underline {
  padding: 2px 120px;
  background-color: #17a2b8;
  position: absolute;
  z-index: 0;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container {
  margin: 40px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container h5 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 25px;
  padding: 10px 0px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container h6 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  padding: 5px 0px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan {
  margin: 10px 0px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .column, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .column .group, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .column .group label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .two__column, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .two__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .two__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .two__column .group, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .two__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .two__column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .two__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .two__column .group label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .two__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .two__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .one__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .one__column .group, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .one__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .one__column .group {
  width: 100%;
  display: block;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .one__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .one__column .group label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .one__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .one__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .three__column, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .three__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .three__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .three__column .group, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .three__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .three__column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .three__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .three__column .group label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .three__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .three__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .four__column, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .four__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .four__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .four__column .group, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .four__column input, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .four__column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .four__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form .four__column .group label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .four__column input label, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .four__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .add__more, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .add__more {
  width: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form .add__more button, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan .add__more button {
  width: 100%;
  border: none;
  background-color: #dc3545;
  color: #fff;
  font-family: sans-serif;
  font-size: 18px;
  height: 25px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form textarea, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan textarea {
  width: 100%;
  height: 150px;
  padding: 8px;
  font-family: sans-serif;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=text], .dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=date], .dashboard__container .dashboard__content .registration__page .registration__form__container .form select, .dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=file], .dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=password], .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=text], .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=date], .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan select, .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=file], .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=password] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: 40px;
  padding: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  border: 1px solid #000;
  margin-bottom: 15px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=text]:nth-child(1), .dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=text]:nth-child(2),
.dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=date]:nth-child(2), .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=text]:nth-child(1), .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=text]:nth-child(2),
.dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=date]:nth-child(2) {
  margin-right: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form input[type=file], .dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan input[type=file] {
  margin-right: 5px;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .form__add_plan {
  width: 60%;
  margin: 20px auto;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container .save__btn {
  width: 150px;
  height: 40px;
  border: none;
  background-color: #17a2b8;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 5px;
  cursor: pointer;
}

.dashboard__container .dashboard__content .registration__page .registration__form__container input[type=submit], .dashboard__container .dashboard__content .registration__page .registration__form__container button[type=submit] {
  width: 100px;
  height: 40px;
  border: none;
  background-color: #17a2b8;
  font-family: sans-serif;
  font-weight: 700;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .details {
  width: 95%;
  margin: 50px auto;
}

.dashboard__container .dashboard__content .details .details__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .details__title h5 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
}

.dashboard__container .dashboard__content .details .details__title h5:last-child {
  text-align: right;
  cursor: pointer;
}

.dashboard__container .dashboard__content .details .details__title h5:last-child a {
  background-color: #f5365c;
  padding: 10px 20px;
  border-radius: 5px;
}

.dashboard__container .dashboard__content .details .details__info {
  margin-top: 20px;
  border: 1px solid #000;
  width: 100%;
  height: auto;
  padding: 20px 10px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

.dashboard__container .dashboard__content .details .details__header {
  margin: 15px 0px;
  border-bottom: 2px solid #000;
}

.dashboard__container .dashboard__content .details .details__header h4 {
  padding-bottom: 8px;
}

.dashboard__container .dashboard__content .details .witness__information {
  margin-right: 5px;
}

.dashboard__container .dashboard__content .details .parties__involved, .dashboard__container .dashboard__content .details .party__registering {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .parties__involved .one__column, .dashboard__container .dashboard__content .details .party__registering .one__column {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 8px;
  border: 2px solid #000;
  width: 100%;
}

.dashboard__container .dashboard__content .details .parties__involved .one__column:first-child, .dashboard__container .dashboard__content .details .party__registering .one__column:first-child {
  margin-right: 5px;
}

.dashboard__container .dashboard__content .details .parties__involved .one__column .column__title, .dashboard__container .dashboard__content .details .party__registering .one__column .column__title {
  padding: 10px 5px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #000;
  margin-bottom: 5px;
}

.dashboard__container .dashboard__content .details .parties__involved .one__column .transactions, .dashboard__container .dashboard__content .details .party__registering .one__column .transactions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .parties__involved .one__column .transactions .group, .dashboard__container .dashboard__content .details .party__registering .one__column .transactions .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.dashboard__container .dashboard__content .details .form, .dashboard__container .dashboard__content .details .form__add_plan {
  margin: 10px 0px;
}

.dashboard__container .dashboard__content .details .form .column, .dashboard__container .dashboard__content .details .form__add_plan .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .form .column input, .dashboard__container .dashboard__content .details .form .column .group, .dashboard__container .dashboard__content .details .form__add_plan .column input, .dashboard__container .dashboard__content .details .form__add_plan .column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .details .form .column input label, .dashboard__container .dashboard__content .details .form .column .group label, .dashboard__container .dashboard__content .details .form__add_plan .column input label, .dashboard__container .dashboard__content .details .form__add_plan .column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .details .form .two__column, .dashboard__container .dashboard__content .details .form__add_plan .two__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .form .two__column input, .dashboard__container .dashboard__content .details .form .two__column .group, .dashboard__container .dashboard__content .details .form__add_plan .two__column input, .dashboard__container .dashboard__content .details .form__add_plan .two__column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .details .form .two__column input label, .dashboard__container .dashboard__content .details .form .two__column .group label, .dashboard__container .dashboard__content .details .form__add_plan .two__column input label, .dashboard__container .dashboard__content .details .form__add_plan .two__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .details .form .one__column input, .dashboard__container .dashboard__content .details .form .one__column .group, .dashboard__container .dashboard__content .details .form__add_plan .one__column input, .dashboard__container .dashboard__content .details .form__add_plan .one__column .group {
  width: 100%;
  display: block;
}

.dashboard__container .dashboard__content .details .form .one__column input label, .dashboard__container .dashboard__content .details .form .one__column .group label, .dashboard__container .dashboard__content .details .form__add_plan .one__column input label, .dashboard__container .dashboard__content .details .form__add_plan .one__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .details .form .three__column, .dashboard__container .dashboard__content .details .form__add_plan .three__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .form .three__column input, .dashboard__container .dashboard__content .details .form .three__column .group, .dashboard__container .dashboard__content .details .form__add_plan .three__column input, .dashboard__container .dashboard__content .details .form__add_plan .three__column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .details .form .three__column input label, .dashboard__container .dashboard__content .details .form .three__column .group label, .dashboard__container .dashboard__content .details .form__add_plan .three__column input label, .dashboard__container .dashboard__content .details .form__add_plan .three__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .details .form .four__column, .dashboard__container .dashboard__content .details .form__add_plan .four__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .form .four__column input, .dashboard__container .dashboard__content .details .form .four__column .group, .dashboard__container .dashboard__content .details .form__add_plan .four__column input, .dashboard__container .dashboard__content .details .form__add_plan .four__column .group {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: block;
}

.dashboard__container .dashboard__content .details .form .four__column input label, .dashboard__container .dashboard__content .details .form .four__column .group label, .dashboard__container .dashboard__content .details .form__add_plan .four__column input label, .dashboard__container .dashboard__content .details .form__add_plan .four__column .group label {
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  padding-bottom: 5px;
}

.dashboard__container .dashboard__content .details .form .add__more, .dashboard__container .dashboard__content .details .form__add_plan .add__more {
  width: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard__container .dashboard__content .details .form .add__more button, .dashboard__container .dashboard__content .details .form__add_plan .add__more button {
  width: 100%;
  border: none;
  background-color: #dc3545;
  color: #fff;
  font-family: sans-serif;
  font-size: 18px;
  height: 25px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
}

.dashboard__container .dashboard__content .details .form textarea, .dashboard__container .dashboard__content .details .form__add_plan textarea {
  width: 100%;
  height: 150px;
  padding: 8px;
  font-family: sans-serif;
}

.dashboard__container .dashboard__content .details .form input[type=text], .dashboard__container .dashboard__content .details .form input[type=date], .dashboard__container .dashboard__content .details .form select, .dashboard__container .dashboard__content .details .form input[type=file], .dashboard__container .dashboard__content .details .form input[type=password], .dashboard__container .dashboard__content .details .form__add_plan input[type=text], .dashboard__container .dashboard__content .details .form__add_plan input[type=date], .dashboard__container .dashboard__content .details .form__add_plan select, .dashboard__container .dashboard__content .details .form__add_plan input[type=file], .dashboard__container .dashboard__content .details .form__add_plan input[type=password] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  height: 40px;
  padding: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  border: 1px solid #000;
  margin-bottom: 15px;
}

.dashboard__container .dashboard__graph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 40px;
  background-color: #f5f5f5;
}

.dashboard__container .dashboard__graph .analytics {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
}

.dashboard__container .dashboard__graph .analytics .available_reg_balance, .dashboard__container .dashboard__graph .analytics .available_search_balance {
  width: 400px;
  height: 200px;
  background-color: #ddd;
  border-radius: 5px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 10px;
  text-align: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.dashboard__container .dashboard__graph .analytics .available_reg_balance .icon, .dashboard__container .dashboard__graph .analytics .available_search_balance .icon {
  height: 150px;
  text-align: center;
  padding: 10px 0px;
}

.dashboard__container .dashboard__graph .analytics .available_reg_balance h5, .dashboard__container .dashboard__graph .analytics .available_search_balance h5 {
  font-size: 18px;
}

.dashboard__container .dashboard__graph .graph__box {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 35%;
          flex: 1 1 35%;
  min-height: 400px;
  width: 100%;
  margin: 5px;
  background-color: #f8f9fe;
}

.dashboard__container .dashboard__graph .graph__box .graph__title {
  padding: 20px;
  font-family: sans-serif;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  border-bottom: 1px solid #000;
}

.dashboard__container .dashboard__graph .revenue__graph {
  border-radius: 5px;
}

.confirm_registration {
  width: 80%;
  margin: 20px auto;
}

.confirm_registration * {
  margin: 0 !important;
  padding: 0 !important;
}

.confirm_registration .confirmation {
  margin-top: 15px !important;
  display: block !important;
  padding: 90px !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-image: url("/src/assets/imgs/second-certificate.jpeg") !important;
  max-width: 150% !important;
  height: 100% !important;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.confirm_registration .confirmation .logo {
  text-align: center;
  padding-top: 40px;
}

.confirm_registration .confirmation .logo h5 {
  font-family: sans-serif;
  padding-top: 8px;
  font-size: 18px;
}

.confirm_registration .confirmation .print__details .print__title {
  border-bottom: 2px solid #000;
  display: inline-block;
  margin: 15px 0px !important;
}

.confirm_registration .confirmation .print__details .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.confirm_registration .confirmation .print__details .column .one__column {
  margin-top: 15px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.confirm_registration .confirmation .print__details .column .one__column h5 {
  padding: 0;
  margin: 0;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 5px;
  padding-top: 5px !important;
}

.confirm_registration .confirmation .print__details .column .one__column p {
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 5px !important;
}

.confirm_registration .confirmation .print__details .column .two__column {
  margin-top: 8px;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.print {
  display: none !important;
  /* margin-top: 40px; */
  /* margin-top: 30px; */
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-image: url("/src/assets/imgs/second-certificate.jpeg") !important;
  max-width: 100%;
  height: auto;
  /* padding: 20px; */
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

.print__details {
  /* margin: 40px 0 !important; */
  margin: 10px 0 !important;
}

.print .logo {
  text-align: center;
  margin-top: 8px !important;
}

.receipt-title {
  font-size: 1.6rem !important;
}

.print .logo h5 {
  font-family: sans-serif;
  padding-top: 8px;
  /* font-size: 18px; */
  font-size: 16px;
}

.print .print__details .print__title {
  border-bottom: 2px solid #000;
  padding: 0px 0px 8px 0px;
  display: inline-block;
  /* margin: 8px 0px; */
  margin: 4px 0;
}

.print .print__details .print__title h4 {
  font-size: 1rem !important;
  margin: 0;
}

.print .print__details .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.print .print__details .column .one__column {
  /* margin-top: 8px; */
  margin-top: 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.print .print__details .column .one__column h5 {
  padding: 0 !important;
  margin: 0 !important;
  letter-spacing: 1px;
  font-weight: bold;
  /* font-size: 16px; */
  font-size: 14px;
  /* padding-bottom: 5px; */
  /* padding-bottom: 3px; */
}

.print .print__details .column .one__column p {
  padding: 0 !important;
  margin: 0 !important;
  /* padding-bottom: 5px; */
  /* padding-bottom: 3px; */
  font-size: 12px;
}

.print .print__details .column .one__column h6 {
  font-size: 14px;
}

.print .print__details .column .two__column {
  /* margin-top: 8px; */
  margin-top: 4px;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

@media print {
  * {
    margin: 0 !important;
    padding: 0 !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  .confirm_registration {
    width: 100% !important;
  }
  .confirm_registration button {
    display: none !important;
  }
  .confirm_registration .confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block !important;
    padding: 48px 52px !important;
    background-image: url("/src/assets/imgs/second-certificate.jpeg") !important;
    -webkit-print-color-adjust: exact !important;
    height: 95% !important;
    max-width: 100%;
    display: block;
  }
  .confirm_registration .confirmation .logo {
    text-align: center;
    padding: 20px 0px;
  }
  .confirm_registration .confirmation .print__details .print__title {
    margin: 4px 0px !important;
  }
  .confirm_registration .confirmation .column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .confirm_registration .confirmation .column .one__column {
    margin-top: 8px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .confirm_registration .confirmation .column .one__column h5 {
    padding: 5px 3px !important;
  }
  .confirm_registration .confirmation .column .one__column p {
    padding: 5px 3px !important;
  }
  .dashboard__container {
    margin: 0;
    padding: 0;
    width: 105% !important;
    font-size: 14px;
  }
  .dashboard__container .sidebar {
    display: none;
  }
  .dashboard__container .dashboard__content .dashboard__title {
    display: none;
  }
  .dashboard__container .dashboard__content .details .details__title {
    display: none;
  }
  .dashboard__container .dashboard__content .details .details__info {
    display: none;
  }
  .dashboard__container .dashboard__content .print {
    display: block !important;
    padding: 48px 52px !important;
    background-image: url("/src/assets/imgs/second-certificate.jpeg") !important;
    -webkit-print-color-adjust: exact !important;
    max-height: 100%;
    max-width: 100%;
    display: block;
  }

  .receipt-title{ 
    font-size: 1.4rem !important;
  }

  .dashboard__container .dashboard__content .print .logo {
    text-align: center;
    padding: 20px 0px;
  }
  .dashboard__container .dashboard__content .print .print__details .print__title {
    margin: 4px 0px !important;
  }
  .dashboard__container .dashboard__content .print .column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .dashboard__container .dashboard__content .print .column .one__column {
    margin-top: 8px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .dashboard__container .dashboard__content .print .column .one__column h5 {
    padding: 5px 3px !important;
  }
  .dashboard__container .dashboard__content .print .column .one__column p {
    padding: 5px 3px !important;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard__container .sidebar .sidebar__navigations ul.main__nav li {
    font-size: 15px;
  }
  .dashboard__container .sidebar .sidebar__navigations ul.main__nav li ul.sub__menu li {
    font-size: 11px;
  }
  .dashboard__container .dashboard__content .dashboard__analytics .cards .card__box {
    height: 120px;
  }
  .dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div h5 {
    font-size: 12px;
  }
  .dashboard__container .dashboard__content .dashboard__analytics .cards .card__box .card__box__header div h6 {
    font-size: 16px;
  }
}
