.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* justify-content: space-between; */
}

.form-group {
  flex-basis: calc(25% - 20px);
}

.input-group {
  display: flex;
  /* flex: 1; */
  /* gap: 10px; */
  width: 100% !important;
  border-radius: 0;
}


.alt .form-group-inner {
    display: flex;
    align-items: center;
}

.alt .form-group-inner .input-group:first-child {
    border-radius: 0;
}

.select-option {
  position: relative !important;
  text-transform: capitalize !important;
  margin: 0 !important;
  padding: 0 !important;
}

.select-option .css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

.select-option .css-1dimb5e-singleValue {
  text-transform: capitalize !important;
}

.select-option .css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}

.select-option .css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

.select-option .css-13cymwt-control {
  border-radius: 0 !important;
}

.select-option .css-hlgwow {
  padding: 0 !important;
}

.select-option .css-19bb58m {
  padding: 0 !important;
  margin: 0 !important;
}
