@import 'variables';
@import 'reset';



/** Login **/
.wrapper-2{
    background: url('../../imgs/arch.jpeg') center center;
    background-size: cover; 
    height: 100vh;
  }
  .wrapper-3{
    background: url('../../imgs/bg3.jpeg') center center;
    background-size: cover; 
    font-weight: bolder;
    /* height: 100vh;  */
}
.wrapper-2-gradient{
    font-weight: bold;

    background-image: linear-gradient(rgba(0, 0, 0, 0.45),rgba(0,0,0,.45));
    height: 100%;
  }
  .wrapper-3-gradient{
    font-weight: bold;
    background-image: linear-gradient(rgba(0, 243, 255, 0.45), rgba(0, 0, 0, 0.45));
    height: 100%;
  }






.login__container {
    width: 100%;
    max-height: 100vh;
    // background-color: #f5f5f5a9;
    .login {
        position: absolute;
        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
        min-width: 35%;
        height: auto;
        background-color: #f5f5f5b6;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 40px; 
        border-radius: 5px;
        .title__container {
            width: 100%;
            height: 150px;
            text-align: center;
            // box-sizing: border-box;
            // overflow: none;
            h5 {
                padding-top: 5px;
                font-family: Arial, Helvetica, sans-serif;
                letter-spacing: 1px;
                font-weight: lighter;
                font-size: 18px;
            }
        } 
        .form__container {
            width: 100%;
            form {
                label {
                    width: 100%;
                    display: inline-block;
                    padding: 8px 0px;
                    font-family: sans-serif;
                }
                input[type=text], input[type=password] {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #D8D8D8;
                    padding: 0px 8px;
                    font-family: sans-serif;
                }

                button[type=submit] {
                    border: none;
                    background-color: #0080A3;
                    width: 100px;
                    height: 40px;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    color: #fff;
                    text-transform: uppercase;
                    font-family: sans-serif;
                    font-weight: 700;
                    letter-spacing: 1px;
                    font-size: 17px;
                    margin-top: 20px;
                    border-radius: 3px;
                    margin-bottom: 15px;
                }
            }
        }
        .create-user {
            padding: 10px 10px 5px 0px ;
            border-bottom: 1px solid black;
            margin-right: 15px;
            .link {
                color: #000;
            }
        }     
    }
}

/** Register User */
.register__user {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 20px 0px;
    .logo__container {
        width: 100%;
        height: 200px;
        display: block;
        text-align: center;
        padding: 10px 0px;
        box-sizing: border-box;
        h1 {
            color: #003997;
            font-family: sans-serif;
        }
    }
    .registration__form {
        width: 80%;
        margin: 40px auto;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;
        height: 100%;
        padding: 20px;
        form {
            h3 {
                padding: 10px 0px 4px 0px;
                font-family: sans-serif;
                font-size: 25px;
                text-decoration: underline;
            }
            .column {
                display: flex;
                input[type=text].outer, input[type=password].outer {
                    width: 100%;
                    margin: 5px;
                    height: 50px;
                    border: 2px solid #000;
                    padding: 0px 8px;
                    font-family: sans-serif;
                    border-radius: 5px;
                }
                .single__column {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin: 5px;
                    select {
                        height: 50px;
                        border-radius: 5px;
                        border: 2px solid #000;
                        margin-bottom: 10px;
                        -moz-appearance:none !important;
                        background: transparent url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==') right center no-repeat !important;
                        background-position: calc(100% - 5px) center !important;
                    }
                    input[type=text].inner, input[type=password].outer {
                        width: 100%;
                        height: 50px;
                        border: 2px solid #000;
                        padding: 0px 8px;
                        font-family: sans-serif;
                        border-radius: 5px;
                    }
                }
            }
            .plan__list {
                text-align: center;
                h2 {
                    font-family: sans-serif;
                    letter-spacing: 1px;
                    font-weight: lighter;
                }
                /* The container */
                    .container {
                        display: inline-block;
                        position: relative;
                        // padding-left: 35px;
                        padding-right: 55px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 22px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }
                    
                    /* Hide the browser's default radio button */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    
                    /* Create a custom radio button */
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: #eee;
                        border-radius: 50%;
                    }
                    
                    /* On mouse-over, add a grey background color */
                    .container:hover input ~ .checkmark {
                        background-color: #ccc;
                    }
                    
                    /* When the radio button is checked, add a blue background */
                    .container input:checked ~ .checkmark {
                        background-color: #2196F3;
                    }
                    
                    /* Create the indicator (the dot/circle - hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    
                    /* Show the indicator (dot/circle) when checked */
                    .container input:checked ~ .checkmark:after {
                        display: block;
                    }
                    
                    /* Style the indicator (dot/circle) */
                    .container .checkmark:after {
                        top: 9px;
                        left: 9px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: white;
                    }
            }
            .plan__container {
                width: 100%;
                display: flex;
                .plan {
                    width: 100%;
                    background-color:#fff;
                    height: 250px;
                    margin: 5px;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    text-align: center;
                    border-radius: 5px;
                    position: relative;
                    span {
                        font-family: sans-serif;
                        font-weight: lighter;
                    }
                    h5 {
                        font-family: sans-serif;
                        font-weight: lighter;
                        letter-spacing: 1px;
                        margin-bottom: 5px;
                    }
                    h6 {
                        font-family: sans-serif;
                        font-weight: bold;
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                    button {
                        position: absolute;
                        width: 100%;
                        height: 40px;
                        border: none;
                        color: #fff;
                        background-color: #17a2b8;
                        left: 0;
                        right: 0;
                        bottom: 0px;
                        font-family: sans-serif;
                        font-weight: bold;
                        border-radius: 0px 0px 5px 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .submit__btn {
        text-align: center;
        input[type=submit] {
            width: 200px;
            height: 50px;
            border: none;
            border-radius: 5px;
            background-color: #17a2b8;
            color: #000;
            font-family: sans-serif;
            letter-spacing: 1px;
            font-size: 18px;
            margin: 20px;
            cursor: pointer;
            font-weight: lighter;
        }
    }
}

// body {
//     height: 100vh;
// }

/** Dashboard */
.dashboard__container {
    width: 100%;
    display: flex;
    position: relative;
    min-height: 100vh;

    .swal-height {
        height: 100px !important;
    }

    /** Sidebar */
    .sidebar {
        flex: 1;
        width: 20%;
        .sidebar__container {
            border-right: 1px solid #f8f8f8;
            height: 100%;
            background-color: #ffffff;
            .sidebar__title {
                display: flex;
                line-height: 70px;
                height: 70px;
                position: relative;
                align-items: center;
                border-bottom: 1px solid #f5f5f5;
                
                img {
                    flex: 1;
                    position: absolute;
                    max-width: 60%;
                    max-height: 60%;
                    left: 10px;
                    cursor: pointer;
                }
                .hamburger__menu {
                    flex: 1;
                    position: absolute;
                    // top: 20px;
                    right: 10px;
                    cursor: pointer;
                    span:first-child {
                        width: 20px !important;
                    }
                    span:last-child {
                        width: 18px !important;
                    }
                    span {
                        width: 30px;
                        display: block;
                        background-color: #000;
                        margin-bottom: 4px;
                        height: 2px;
                    }
                }
            }
        }
        .sidebar__navigations {
            ul.main__nav {
                padding: 10px;
                list-style: none;
                font-family: sans-serif;
                li {
                    display: block;
                    font-size: 18px;
                    ul.main_menu {
                        list-style: none;
                        padding: 10px;
                        transition: 0.3s ease-in-out;
                        margin-bottom: 15px;
                        li {
                            display: inline-block;
                            // width: 100%;
                            .main__link {
                                color: #777;
                            }
                            i{
                                color: #777;
                            }
                            // i.fas.fa-user {
                            //     color: #fb6340!important;
                            // }
                            // i.fas.fa-sign-out-alt {
                            //     color: #f5365c!important;
                            // }
                            // i.fas.fa-chevron-down {
                            //     color: #000;
                            // }
                            // i.fas.fa-tachometer-alt {
                            //     color: #5e72e4!important;
                            // }
                            // i.fas.fa-times {
                            //     color: #11cdef!important;
                            // }
                            // i.fab.fa-searchengin {
                            //     color: #f3a4b5!important;
                            // }
                            // i.fas.fa-user-shield {
                            //     color: #2dce89!important;
                            // }
                        }
                        li:first-child {
                            width: 90%;
                        }
                        li:last-child {
                            width: 10%;
                            text-align: right;
                        }
                        
                       

                    }
                    ul.main_menu:hover {
                        // padding: 10px 8px;
                        background-color: #EFF7FE;

                        border-radius: 5px;
                        cursor: pointer;

                        li{
                            .main__link{
                                color: $yale-blue
                            }
                            i{
                                color: $yale-blue !important;

                            }
                        }
                    }
                    ul.main_menu.active {
                        background-color: #EFF7FE;
                        color: white !important;
                        border-radius: 5px;

                        li{
                            .main__link{
                                color: $yale-blue
                            }
                            i{
                                color: $yale-blue !important;

                            }
                        }
                    }
                    

                    ul.sub__menu {
                        // text-align: center;
                        transition: 0.3s ease-out;
                        padding: 0px 60px;
                        li {
                            padding: 5px 0px;
                            text-align: left;
                            font-size: 13px;
                            white-space: nowrap;
                            // font-weight: lighter;
                            font-family: sans-serif;
                            // letter-spacing: 1px;
                            font-weight: bold;
                            cursor: pointer;
                            .link {
                                color: #000;
                            }
                        }
                        ul {
                            li {
                                padding-left: 10px;
                                .link {
                                    color: rgb(0, 51, 128);
                                }
                            }
                        }
                        li:hover {
                            text-decoration: underline;
                        }
                    }
                    ul.sub__menu.close {
                        display: none;
                        transition: 0.5s ease-in-out;
                        opacity: 0;
                    }
                    ul.sub__menu.open {
                        display: block;
                        transition: 0.5s ease-in-out;
                    }
                   
                    // ul.sub__menu.slide {
                    //     transition: max-height 0.5s ease-in-out;
                    // }
                }
               
            }
           
        }
    }
    .dashboard__content {
        flex: 4;
        width: 80%;
        position: relative;
        background-color: #f5f5f5;
        border-left: 1px solid #ccc;
        .subscription__container {
            width: 90%;
            margin: 40px auto;
            h2 {
                font-family: 'Courier New', Courier, monospace;
                font-size: 25px;
                letter-spacing: 1px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 20px;
            }
              .plan__list {
                text-align: center;
                h2 {
                    font-family: sans-serif;
                    letter-spacing: 1px;
                    font-weight: lighter;
                }
                /* The container */
                    .container {
                        display: inline-block;
                        position: relative;
                        padding-left: 35px;
                        padding-right: 55px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 22px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }
                    
                    /* Hide the browser's default radio button */
                    .container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    
                    /* Create a custom radio button */
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: #eee;
                        border-radius: 50%;
                    }
                    
                    /* On mouse-over, add a grey background color */
                    .container:hover input ~ .checkmark {
                        background-color: #ccc;
                    }
                    
                    /* When the radio button is checked, add a blue background */
                    .container input:checked ~ .checkmark {
                        background-color: #2196F3;
                    }
                    
                    /* Create the indicator (the dot/circle - hidden when not checked) */
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    
                    /* Show the indicator (dot/circle) when checked */
                    .container input:checked ~ .checkmark:after {
                        display: block;
                    }
                    
                    /* Style the indicator (dot/circle) */
                    .container .checkmark:after {
                        top: 9px;
                        left: 9px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: white;
                    }
            }
            .plan__container {
                width: 100%;
                display: flex;
                .plan {
                    width: 100%;
                    background-color:#fff;
                    height: 250px;
                    margin: 5px;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    text-align: center;
                    border-radius: 5px;
                    position: relative;
                    span {
                        font-family: sans-serif;
                        font-weight: lighter;
                    }
                    h5 {
                        font-family: sans-serif;
                        font-weight: lighter;
                        letter-spacing: 1px;
                        margin-bottom: 5px;
                    }
                    h6 {
                        font-family: sans-serif;
                        font-weight: bold;
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                    button {
                        position: absolute;
                        width: 100%;
                        height: 40px;
                        border: none;
                        color: #fff;
                        background-color: #17a2b8;
                        left: 0;
                        right: 0;
                        bottom: 0px;
                        font-family: sans-serif;
                        font-weight: bold;
                        border-radius: 0px 0px 5px 5px;
                        cursor: pointer;
                    }
                }
            }
            .subscription__btn {
                padding: 15px 50px;
                border: none;
                margin-top: 20px;
                text-align: center;
                background-color: $secondary-color;
                border-radius: 5px;
                color: #fff;
                font-weight: 500;
                font-size: 15px;
                cursor: pointer;
            }
        }
        .dashboard__title {
            padding: 20px 15px;
            // border-bottom: 1px solid #f8f9fe;
            display: flex;
            // background-color: #ffffff!important;
            // background-color: #11cdef!important;
            background-color: #e8e8e8;
            background-image: linear-gradient(0deg, #e8e8e8 0%, #ffffff 100%);
            

            .dash__left {
                flex: 1;
            }
            .dash__right {
                flex: 1;
                text-align: right;
                ul {
                    list-style: none;
                    li {
                        display: inline-block;
                        i {
                            font-size: 18px;
                        }
                    }
                    li:first-child {
                        padding-right: 25px;
                    }
                }
            }
        }

        .reporting {
            width: 60%;
            margin: 40px auto;
            h2 {
                font-family: 'Courier New', Courier, monospace;
                margin-bottom: 20px;
                text-align: center;
            }
            form {
                display: flex;
                input[type=text] {
                    height: 50px;
                    padding: 0px 8px;
                    font-family: sans-serif;
                    width: 100%;
                    border-radius: 5px 0px 0px 5px;
                    border: none;
                }
                button[type=submit] {
                    width: 75px;
                    height: 50px;
                    background-color: $secondary-color;
                    color: #000;
                    border: none;
                    border-radius: 0px 5px 5px 0px;
                    cursor: pointer;
                    i.fas.fa-search, i.fas.fa-spinner.fa-spin {
                        font-size: 20px;
                    }
                }
            }
        }

        .dashboard__analytics {
            min-height: 210px;
            background-color: $baby-blue-eyes;
            .cards {
                padding: 40px;
                display: flex;
                .card__box {
                    width: 200px;
                    height: 120px;
                    background-color: #f8f9fe;
                    border-radius: 5px;
                    flex: 1;
                    align-items: center;
                    margin: 5px;
                    padding: 20px;
                    box-sizing: border-box;
                    .card__box__header {
                        display: flex;
                        padding: 5px;
                        align-items: center;
                        div {
                            flex: 1;
                            h5 {
                                text-transform: uppercase;
                                color: #8898aa!important;
                                font-size: 14px;
                                padding-bottom: 6px;
                                font-weight: 500;
                                font-family: Open Sans,sans-serif;
                                letter-spacing: 1px;
                            }
                            h6 {
                                font-size: 20px;
                                font-weight: 700;
                            }
                            i.fas.fa-user {
                                padding: 10px;
                                background-color: rosybrown;
                                border-radius: 25px;
                            }
                            i.fas.fa-search, i.fas.fa-file {
                                padding: 10px;
                                background-color: salmon;
                                border-radius: 25px;
                            }
                            i.fas.fa-user-shield {
                                padding: 10px;
                                background-color: sienna;
                                border-radius: 25px;
                            }
                            i.fas.fa-edit {
                                padding: 10px;
                                background-color: teal;
                                border-radius: 25px;
                            }

                        }
                        div.card__icon {
                            text-align: right;
                        }
                    } 
                    .card__analytics {
                        margin-top: 20px;
                        h5 {
                            color: #2dce89!important;
                            span {
                                font-weight: lighter;
                                letter-spacing: 1px;
                                color: #000 !important;
                            }
                        }
                    }
                }

            }
        }

        .breadcrumb__title {
            width: 95%;
            margin: 10px auto;
            padding: 20px;
            font-family: sans-serif;
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 1px;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 3px solid #dddddd;
        }

        .add-search {
            width: 95%;
            margin: 10px auto;
            padding: 10px 0px;
            align-items: center;
            vertical-align: middle;
            display: flex;
            .add {
                flex: 1;
                text-align: left;
                a {
                    padding: 10px 20px;
                    background-color: $secondary-color;
                    color: #fff;
                    font-family: sans-serif;
                    font-weight: 500;
                    letter-spacing: 1px;
                }
            }

            #global {
                display: flex;
                .global__search {
                   width: 30%;
                }
            }
          
            .search {
                flex: 1;
                text-align: right;
               
                form {
                    // display: flex;
                    input[type=text], button {
                        height: 40px;
                    }
                    button {
                        width: 50px;
                        border: none;
                        background-color: $secondary-color;
                        border: 1px solid #dddddd;
                    }
                    input[type=text] {
                        padding: 8px;
                        font-family: sans-serif;
                        width: 70%;
                        border: 1px solid #dddddd;
                    }
                }
            }
        }

        // registration forms
        .table__container {
            max-width: 95%;
            margin: 20px auto;
            border-radius: 5px;
            height: auto;
            overflow-x: scroll;
            padding: 20px 0px;
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                overflow-x: scroll;
                border: none;
                // width: 180%;
                width: 100%;
              }
              
              td, th {
                border: 1px solid rgba(0, 0, 0, 0.13);
                text-align: left;
                padding: 8px;
              }

              th {
                  background-color: $yale-blue;
                  color: white;
              }

              td {
                 ul {
                     list-style: none;
                     text-align: center;
                     li {
                         display: inline-block;
                         padding: 10px 20px;
                         a {
                           padding: 5px 20px;
                           background-color: $secondary-color;
                           color: #fff;
                           border-radius: 5px;

                         }
                         
                     }
                   
                 }
                 .approved__btn {
                     padding: 10px;
                     display: block;
                     width: 100%;
                     background-color: #66cd9a;
                     color: #fff;
                     border: none;
                     border-radius: 5px;
                     font-weight: bold;
                     
                 }

                 .approved__btn_RA, .approved__btn_user, .rejected__btn {
                    padding: 10px;
                    display: block;
                    width: 100%;
                    color: #fff;
                    border: none;    
                    font-weight: bold;
                    // border-radius: 5px;

                 }
                 .approved__btn_RA {
                    background-color: #5e72e4;

                }
                .approved__btn_user {
                    background-color: #0080A3;

                }
                .rejected__btn{
                    background-color:  #ec4141;
                }

                .info__btn, .delete__btn{
                    display: block;
                    width: 100%;
                    border-radius: 5px;   
                    cursor: pointer;          
                }
                .info__btn{
                    color: #26363b;
                    border: 2px solid #26363baf;
                    padding: 5px;

                    // background-color: white;
                    margin-top: 5px;
                    
                    &:hover{
                        background-color: rgb(255, 255, 255);
                    }
                }

                .delete__btn{
                    padding: 10px;
                    background-color: rgb(232, 54, 99);
                    color: white;
                    border: none;
                    &:hover{
                        background-color: rgb(219, 33, 33);
                    }
                }
                 a.view {
                    padding: 5px 20px;
                    background-color: $secondary-color;
                    color: #fff;
                    border-radius: 5px;

                  }

                  a.delete {
                    padding: 5px 20px;
                    background-color: #f5365c;
                    color: #fff;
                    border-radius: 5px;
                  }

                  .btn__approve {
                      background-color: #66cd9a;
                      border: none;
                      border-radius: 5px;
                      padding: 5px 20px;
                      color: #fff;
                      margin-bottom: 5px;
                      width: 100%;
                      cursor: pointer;

                      &:hover{
                          background-color: #12a766;
                      }
                  }

                  .btn__reject {
                    background-color: rgb(232, 54, 99);
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    padding: 5px 20px;
                    margin-bottom: 5px;
                    width: 100%;
                    cursor: pointer;

                    &:hover{
                        background-color: rgb(219, 33, 33);
                    }
                  }
              }
              
              tr:nth-child(even) {
                background-color: #dddddd;
              }
        }

        .pagination {
            width: 95%;
            margin: auto;
            display: flex;
            justify-content: end;
            .pagination__btn {
                // width: 100px;
                // height: 40px;
                border: none;
                padding: 10px 20px;
                background-color: #66cd9a;
                font-weight: bold;
                border-radius: 5px;
                // flex: 1;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                color: #000;
                cursor: pointer;
                margin: 5px;
            }
        }

        // add document
        .upload__container {
            margin: 40px;
            h2 {
                font-family: sans-serif;
                border-bottom: 2px solid black;
                padding: 5px 0px;
            }
            form {
                margin-top: 100px;
                label {
                    padding-right: 200px;
                    font-size: 15px;
                    font-family: sans-serif;
                    font-weight: bold;
                }
                input[type=file] {
                    border: 1px solid black;
                    padding: 10px;
                    margin-right: 10px;
                    border-radius: 5px;
                }
                input[type=submit] {
                    // width: 100%;
                    padding: 13px 20px;
                    border: none;
                    background-color: $secondary-color;
                    font-family: sans-serif;
                    letter-spacing: 1px;
                    border-radius: 5px;
                }

            }
        }

        // view document

        .document__container {
            margin: 40px;
            height: auto;
            // width: 100%;
            background-color: #fff;
            padding: 20px;
            h2 {
                padding-bottom: 10px;
                font-family: sans-serif;
            }
            .document {
                width: 180px;
                // height: 120px;
                background-color: #f5f5f5;
                padding: 10px;
                box-sizing: border-box;
                display: inline-block;
                margin: 5px;
                .view__download {
                    ul {
                        list-style: none;
                        li {
                            display: inline-block;
                            padding-right: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        // kombo deed registration page
        .registration__page {
            position: relative;
            h3 {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                margin: 20px 0px 0px 20px;
                font-size: 30px;
                z-index: 9999;
            }
            .underline {
                padding: 2px 120px;
                background-color: $secondary-color;
                position: absolute;
                z-index: 0;
            }
            .registration__form__container {
                margin: 40px;
                h5 {
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    font-size: 25px;
                    padding: 10px 0px;
                }
                h6 {
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    font-size: 18px;
                    padding: 5px 0px;
                }
                .form, .form__add_plan {
                    .column {
                        display: flex;
                        input, .group {
                            flex: 1;
                            display: block;
                            label {
                                display: block;
                                font-family: sans-serif;
                                font-size: 14px;
                                padding-bottom: 5px;
                            }
                        }
                    }
                    .two__column {
                        display: flex;
                        input, .group {
                            flex: 1;
                            display: block;
                            label {
                                display: block;
                                font-family: sans-serif;
                                font-size: 14px;
                                padding-bottom: 5px;
                            }
                        }
                    }
                    .one__column {
                        input, .group {
                            width: 100%;
                            display: block;
                            label {
                                display: block;
                                font-family: sans-serif;
                                font-size: 14px;
                                padding-bottom: 5px;
                            }
                        }
                    }
                    .three__column {
                        display: flex;
                        input, .group {
                            flex: 1;
                            display: block;
                            label {
                                display: block;
                                font-family: sans-serif;
                                font-size: 14px;
                                padding-bottom: 5px;
                            }
                        }
                    }
                    .four__column {
                        display: flex;
                        input, .group {
                            flex: 1;
                            display: block;
                            label {
                                display: block;
                                font-family: sans-serif;
                                font-size: 14px;
                                padding-bottom: 5px;
                            }
                        }
                        
                    }
                    .add__more {
                        width: 5%;
                        display: flex;
                        button {
                            width: 100%;
                            border: none;
                            background-color: $primary-color;
                            color: #fff;
                            font-family: sans-serif;
                            font-size: 18px;
                            height: 25px;
                            cursor: pointer;
                            margin-right: 8px;
                            margin-bottom: 8px;
                        }
                    }
                    margin: 10px 0px;
                    textarea {
                        width: 100%;
                        height: 150px;
                        padding: 8px;
                        font-family: sans-serif;
                    }
                    input[type=text], input[type=date], select, input[type=file], input[type=password] {
                        flex: 1;
                        width: 100%;
                        height: 40px;
                        padding: 8px;
                        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                        font-weight: 500;
                        border: 1px solid #000;
                        margin-bottom: 15px;
                    }

                    input[type=text]:nth-child(1),  input[type=text]:nth-child(2),
                    input[type=date]:nth-child(2)  {
                        margin-right: 5px;
                    }
                    input[type=file] {
                        margin-right: 5px;
                    }
                }
                .form__add_plan {
                    width: 60%;
                    margin: 20px auto;
                }
                .save__btn {
                    width: 150px;
                    height: 40px;
                    border: none;
                    background-color: $secondary-color;
                    font-size: 15px;
                    font-weight: 500;
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    margin: 5px;
                    cursor: pointer;
                }
                input[type=submit], button[type=submit] {
                    width: 100px;
                    height: 40px;
                    border: none;
                    background-color: $secondary-color;
                    font-family: sans-serif;
                    font-weight: 700;
                    border-radius: 5px;
                }
            }
        }

        .details {
            width: 95%;
            margin: 50px auto;
            .details__title {
                display: flex;
                h5 {
                    flex: 1;
                    font-family: sans-serif;
                    letter-spacing: 1px;
                    font-weight: 700;
                    &:last-child {
                        text-align: right;
                        cursor: pointer;
                        a {
                            background-color: #f5365c;
                            padding: 10px 20px;
                            border-radius: 5px;
                        }
                    }
                }
            }

            .details__info {
                margin-top: 20px;
                border: 1px solid #000;
                width: 100%;
                height: auto;
                padding: 20px 10px;
                font-family: Arial, Helvetica, sans-serif;
                letter-spacing: 1px;
            }

            .details__header {
                margin: 15px 0px;
                border-bottom: 2px solid #000;
                h4 {
                    padding-bottom: 8px;
                }

            }
            .witness__information {
                margin-right: 5px;
            }
            .parties__involved, .party__registering {
                display: flex;
               
                .one__column {
                    flex: 1;
                    padding: 8px;
                    border: 2px solid #000;
                    width: 100%;
                   &:first-child {
                       margin-right: 5px;
                   }

                   .column__title {
                       padding: 10px 5px;
                       background-color: #f8f8f8;
                       border-bottom: 1px solid #000;
                       margin-bottom: 5px;
                   }

                   .transactions {
                       display: flex;
                       .group {
                           flex: 1;
                       }
                   }
                }
            }

            .form, .form__add_plan {
                .column {
                    display: flex;
                    input, .group {
                        flex: 1;
                        display: block;
                        label {
                            display: block;
                            font-family: sans-serif;
                            font-size: 14px;
                            padding-bottom: 5px;
                        }
                    }
                }
                .two__column {
                    display: flex;
                    input, .group {
                        flex: 1;
                        display: block;
                        label {
                            display: block;
                            font-family: sans-serif;
                            font-size: 14px;
                            padding-bottom: 5px;
                        }
                    }
                }
                .one__column {
                    input, .group {
                        width: 100%;
                        display: block;
                        label {
                            display: block;
                            font-family: sans-serif;
                            font-size: 14px;
                            padding-bottom: 5px;
                        }
                    }
                }
                .three__column {
                    display: flex;
                    input, .group {
                        flex: 1;
                        display: block;
                        label {
                            display: block;
                            font-family: sans-serif;
                            font-size: 14px;
                            padding-bottom: 5px;
                        }
                    }
                }
                .four__column {
                    display: flex;
                    input, .group {
                        flex: 1;
                        display: block;
                        label {
                            display: block;
                            font-family: sans-serif;
                            font-size: 14px;
                            padding-bottom: 5px;
                        }
                    }
                    
                }
                .add__more {
                    width: 5%;
                    display: flex;
                    button {
                        width: 100%;
                        border: none;
                        background-color: $primary-color;
                        color: #fff;
                        font-family: sans-serif;
                        font-size: 18px;
                        height: 25px;
                        cursor: pointer;
                        margin-right: 8px;
                        margin-bottom: 8px;
                    }
                }
                margin: 10px 0px;
                textarea {
                    width: 100%;
                    height: 150px;
                    padding: 8px;
                    font-family: sans-serif;
                }
                input[type=text], input[type=date], select, input[type=file], input[type=password] {
                    flex: 1;
                    width: 100%;
                    height: 40px;
                    padding: 8px;
                    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                    font-weight: 500;
                    border: 1px solid #000;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .dashboard__graph {
        display: flex;
        flex-wrap: wrap;
        padding: 40px;
        background-color: #f5f5f5;
        .analytics {
            padding: 10px;
            display: flex;
            margin: auto;
            .available_reg_balance, .available_search_balance {
                width: 400px;
                height: 200px;
                background-color: #ddd;
                border-radius: 5px;
                flex: 1;
                margin: 10px;
                text-align: center;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                .icon {
                    height: 150px;
                    text-align: center;
                    padding: 10px 0px;
                }
                h5 {
                    font-size: 18px;
                }
            }
        }
        .graph__box {
            width: 100%;
            flex: 1 1 35%;
            min-height: 400px;
            // border-radius: 5px;
            width: 100%;
            margin: 5px;
            background-color: #f8f9fe;
          //  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            .graph__title {
                padding: 20px;
                font-family: sans-serif;
                font-weight: 600;
                color: #000;
                text-transform: uppercase;
                letter-spacing: 0.8px;
                border-bottom: 1px solid #000;
            }
        }

        .revenue__graph {
            // background-color: #32325d;
            border-radius: 5px;
        }
    }
}

.confirm_registration {
    width: 80%;
    margin: 20px auto;
    // text-align: right;
    * {
        margin: 0 !important;
        padding: 0 !important;
    }
    .confirmation {
        margin-top: 15px !important;
        display: block !important;
        padding: 90px !important;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        background-image: url('/src/assets/imgs/second-certificate.jpeg') !important;
        max-width: 150% !important;
        height: 100% !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        .logo {
            text-align: center;
            padding-top: 40px;
            h5 {
                font-family: sans-serif;
                padding-top: 8px;
                font-size: 18px;
            }
        }
    
        .print__details {
            .print__title {
                border-bottom: 2px solid #000;
                // padding: 15px 0px 8px 0px;
                display: inline-block;
                margin: 15px 0px !important;
                
            }
            .column {
                display: flex;
                .one__column {
                    margin-top: 15px;
                    flex: 1;
                    h5 {
                        padding: 0;
                        margin: 0;
                        letter-spacing: 1px;
                        font-weight: bold;
                        font-size: 16px;
                        padding-bottom: 5px;
                        padding-top: 5px !important;
                    }
                    p {
                        padding: 0;
                        margin: 0;
                        padding-bottom: 5px;
                        padding-top: 5px !important;
                    }
                }
                .two__column {
                    margin-top: 8px;
                    flex: 2;
                }
            }
        }
    
}
}

.print  {
    display: none !important;
    margin-top: 40px;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    background-image: url('/src/assets/imgs/second-certificate.jpeg') !important;
    max-width: 100%;
    height: auto;
    // display: block;
    padding: 20px;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    .logo {
        text-align: center;
        h5 {
            font-family: sans-serif;
            padding-top: 8px;
            font-size: 18px;
        }
    }

    .print__details {
        .print__title {
            border-bottom: 2px solid #000;
            padding: 0px 0px 8px 0px;
            display: inline-block;
            margin: 8px 0px;
        }
        .column {
            display: flex;
            .one__column {
                margin-top: 8px;
                flex: 1;
                h5 {
                    padding: 0;
                    margin: 0;
                    letter-spacing: 1px;
                    font-weight: bold;
                    font-size: 16px;
                    padding-bottom: 5px;
                }
                p {
                    padding: 0;
                    margin: 0;
                    padding-bottom: 5px;
                }
            }
            .two__column {
                margin-top: 8px;
                flex: 2;
            }
        }
    }

}

@media print {
    * {
        margin: 0 !important;
        padding: 0 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }
    .confirm_registration {
        width: 100% !important;
        // height: 80% !important;
        button {
            display: none !important;
        }
        .confirmation {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block !important;
            // margin-top: 10px 0px;
            padding: 48px 52px !important;
            background-image: url('/src/assets/imgs/second-certificate.jpeg') !important;
            // background-repeat: no-repeat !important;
            // background-size: 100% 100% !important;
            -webkit-print-color-adjust: exact !important;
            // width: 100%;
           
            // display: inline-block;
            // height: 100%;
            // box-sizing: border-box !important;
            // width: 100%;
            height: 95% !important;
            max-width: 100%;
            display: block;
            // position: relative;;
            // top:0px;
            // bottom:0px;
            // margin: auto;
            // margin-top: 0px !important;
            .logo {
                text-align: center;
                padding: 20px 0px;
            }
            .print__details {
                .print__title {
                    margin: 4px 0px !important;
                }
            }
            .column {
                display: flex;
                .one__column {
                    margin-top: 8px;
                    flex: 1;
                    h5 {
                        padding: 5px 3px!important;
                    }
                    p {
                        padding: 5px 3px !important;
                    }
                }
            }
        }
    
    }
    .dashboard__container {
        margin: 0;
        padding: 0;
        width: 105% !important;
        font-size: 14px;
        .sidebar {
            display: none;
        }
        .dashboard__content {
            .dashboard__title {
                display: none;
            }
            .details {
                .details__title {
                    display: none;
                }
                .details__info {
                    display: none;
                }
            }
            .print {
                display: block !important;
                // margin-top: 10px 0px;
                padding: 48px 52px !important;
                background-image: url('/src/assets/imgs/second-certificate.jpeg') !important;
                // background-repeat: no-repeat !important;
                // background-size: 100% 100% !important;
                -webkit-print-color-adjust: exact !important;
                // width: 100%;
               
                // display: inline-block;
                // height: 100%;
                // box-sizing: border-box !important;
                // width: 100%;
                max-height:100%;
                max-width: 100%;
                display: block;
                // position: relative;;
                // top:0px;
                // bottom:0px;
                // margin: auto;
                // margin-top: 0px !important;
                .logo {
                    text-align: center;
                    padding: 20px 0px;
                }
                .print__details {
                    .print__title {
                        margin: 4px 0px !important;
                    }
                }
                .column {
                    display: flex;
                    .one__column {
                        margin-top: 8px;
                        flex: 1;
                        h5 {
                            padding: 5px 3px!important;
                        }
                        p {
                            padding: 5px 3px !important;
                        }
                    }
                }
            }
        }
    }
 }

@media screen and (max-width: 1200px) {
    .dashboard__container {
       .sidebar {
        .sidebar__navigations {
            ul.main__nav {
               li {
                   font-size: 15px;
                   ul.sub__menu {
                    li {
                        font-size: 11px;
                    }
                    }
               }
               
            }
        }
       }
       .dashboard__content {
           .dashboard__analytics {
               .cards {
                 .card__box {
                    height: 120px;
                     .card__box__header {
                         div {
                            h5 {
                                font-size: 12px;
                            }
                            h6 {
                                font-size: 16px;
                            }
                         }
                     }
                 }
               }
           }
       }
    }
} 
