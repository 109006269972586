@import 'variables';
@import 'reset';

.custom-card-1{
  background: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 3.4rem 0 rgba(0,0,0,0.1);
  margin: 0 auto 10rem;
  padding: 3rem 4rem;
}


.nav-link{
    color: $yale-blue;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: inherit;
    color: $secondary-color;
    border: 1px solid $secondary-color;
    border-left: none;
    border-right: none;
}

.btn2{
  padding: 5px 20px;
  background-color: #17a2b8;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}